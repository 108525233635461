import {
  fragments,
  requestActionFragments,
  searchActionFragments,
  searchMergeFragments,
} from "core/apollo/graphql/fragments";
import { REDIRECT_PARAM } from "core/consts";
import {
  RequestActionType,
  SearchActionsWithHandlers,
  SearchTable,
  UndoActionType,
} from "core/types";
import gql from "graphql-tag";
import { account, encryptionContext } from "./fragments/fragments";

export const GET_AUCTIONREQUEST = gql`
  query getAuctionRequest($id: Int!, $ref: String) {
    auctionRequest(id: $id, ref: $ref)
    @rest(type: "AuctionRequest", path: "auctionrequests/{args.id}") {
      ${fragments.auctionRequest()}
    }
  }
`;

export const GET_CAREPROVIDER_REQUESTS_COUNT = gql`
  query getCareproviderRequestsCount($id: Int!, $filters: String!) {
    requestCount(id: $id, filters: $filters)
      @rest(
        type: "CareproviderDashboardCounts"
        path: "careproviders/requestscount/{args.id}?{args.filters}"
      ) {
      ${fragments.requestCounts()}
    }
  }
`;

export const GET_CARESEEKER_PATIENTS_COUNT = gql`
  query getCareseekerRequestsCount($id: Int!, $filters: String!) {
    patientCount(id: $id, filters: $filters)
      @rest(
        type: "PatientCounts"
        path: "careseekers/{args.id}/patients/count?{args.filters}"
      ) {
      ${fragments.patientCounts()}
    }
  }
`;

export const GET_CAREPROVIDER_LISTING = gql`
  query getProviderListing($filters: String!) {
    providerListing(filters: $filters)
      @rest(
        type: "ProviderListing"
        path: "careproviders/b2csearch?{args.filters}"
      ) {
        ${fragments.providerListingB2C}
    }
  }
`;

export const GET_CAREPROVIDER_LISTING_DETAILS = gql`
  query getProviderListingDetails($careproviderId: Int!, $filters: String!) {
    providerListingDetails(careproviderId: $careproviderId, filters: $filters)
      @rest(
        type: "ProviderListingDetails"
        path: "careproviders/b2c/{args.careproviderId}?{args.filters}"
      ) {
        ${fragments.getCareproviderListingDetails()}
    }
  }
`;

export const GET_CAREPROVIDER_REQUESTS_NEW = (options?: {
  withRequestsLight: boolean;
}) => gql`
  query getCareproviderRequestsNew($id: Int!, $arguments: String!) {
    careproviderRequestsNew(id: $id, arguments: $arguments)
    @rest(type: "AuctionRequestsPayload", path: "careproviders/requestsnew/{args.id}?{args.arguments}${
      options?.withRequestsLight ? "&with_light=true" : ""
    }") {
      requests {
        ${fragments.getCareproviderRequests()}
      }
      total
      remaining
      ids
      redacted_count
      ${options?.withRequestsLight ? fragments.lightRequests : ""}
    }
  }
`;

export const GET_CAREPROVIDER = ({
  withEvents,
  withInternalNotes,
  withMetrics,
  withRoles,
}: {
  withEvents?: boolean;
  withInternalNotes?: boolean;
  withMetrics?: boolean;
  withRoles?: boolean;
} = {}) => gql`
  query getCareprovider($id: Int!) {
    careprovider(id: $id)
    @rest(type: "Careprovider", path: "careproviders/{args.id}?${
      withEvents ? "events=true" : "pending_messages=true"
    }${withMetrics ? "&metrics=true" : ""}${
      withInternalNotes ? "&fields=notes,assignee" : ""
    }") {
      ${fragments.getCareprovider({ withRoles })}
    }
  }
`;

export const GET_MANUALLY_ADDED_CAREPROVIDERS = gql`
  query getManuallyAddedCareproviders {
  careproviders
    @rest(
      type: "[Careprovider]"
      path: "careproviders/manuallyadded"
      method: "GET"
    ){
      ${fragments.manuallyAddedProvider}
    }
  }`;

export const GET_CAREPROVIDER_TOKEN = ({
  token,
  withEvents,
  withMetrics,
}: {
  token: string;
  withEvents?: boolean;
  withMetrics?: boolean;
}) => gql`
  query getCareprovider($id: Int!) {
    careprovider(id: $id)
    @rest(type: "Careprovider", path: "careproviders/{args.id}?token=${token}&${
      withEvents ? "events=true" : "pending_messages=true"
    }${withMetrics ? "&metrics=true" : ""}") {
      ${fragments.getCareprovider()}
    }
  }
`;

export const UPDATE_INSURANCE = gql`
  mutation updateInsurance($id: Int!, $input: Any!) {
    insurance(id: $id, input: $input)
    @rest(type: "Insurance", path: "insurances/{args.id}", method: "PUT") {
      ${fragments.insurance()}
    }
  }
`;

export const GET_INSURANCE = gql`
    query getInsurance($id: Int!) {
      insurance(id: $id)
      @rest(type: "Insurance", path: "insurances/{args.id}") {
        ${fragments.insurance()}
      }
    }
  `;

export const CREATE_INSURANCE = gql`
  mutation createInsurance($input: Any!) {
    insurance(input: $input)
    @rest(type: "Insurance", path: "insurances", method: "POST") {
      ${fragments.insurance()}
    }
  }
`;

export const SEND_FAX_FOR_AUCTION_REQUEST = gql`
  mutation sendFaxForAuctionRequest($auctionRequestId: Int!, $input: Any!) {
    fax(auctionRequestId: $auctionRequestId, input: $input)
      @rest(
        type: "FaxResponse"
        path: "requests/{args.auctionRequestId}/fax"
        method: "POST"
      ) {
      url
    }
  }
`;

export const REQUEST_BULK_ACTION = gql`
  mutation requestBulkAction(
    $account_id: Int!
    $action_type: String!
    $careprovider_id: Int!
    $input: Any!
  ) {
    requestBulkAction(
      account_id: $account_id
      action_type: $action_type
      careprovider_id: $careprovider_id
      input: $input
    )
      @rest(
        type: "[AuctionRequest]"
        path: "bulkaction/careproviders/{args.careprovider_id}?action_type={args.action_type}"
        method: "PUT"
      ) {
      id
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($input: Any!) {
    company(input: $input)
    @rest(type: "Company", path: "company", method: "POST") {
      ${fragments.company()}
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: Int! $input: Any!) {
    company(id: $id, input: $input)
    @rest(type: "Company", path: "company/{args.id}", method: "PUT") {
      ${fragments.company()}
    }
  }
`;

export const UPDATE_COMPANY_TOKEN = gql`
  mutation updateCompany($id: Int! $input: Any! $token: String!) {
    company(id: $id, input: $input, token: $token)
    @rest(type: "Company", path: "company/{args.id}?company_token={args.token}", method: "PUT") {
      ${fragments.company()}
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($id: Int!) {
    company(id: $id)
    @rest(type: "Company", path: "company/{args.id}?", method: "GET") {
      ${fragments.company()}
    }
  }
`;

export const GET_COMPANY_TOKEN = gql`
  query getCompany($id: Int! $token: String!) {
    company(id: $id, token: $token)
    @rest(type: "Company", path: "company/{args.id}?company_token={args.token}", method: "GET") {
      ${fragments.company()}
    }
  }
`;

export const UPDATE_CAREPROVIDER = gql`
  mutation updateCareprovider($id: Int! $input: Any!) {
    careprovider(id: $id, input: $input)
    @rest(type: "Careprovider", path: "careproviders/{args.id}", method: "PUT") {
      ${fragments.careprovider()}
    }
  }
`;

export const UPLOAD_CAREPROVIDER_DPA = gql`
  mutation uploadDpaFile($careproviderId: Int!, $input: Any!) {
    uploadDpaFile(careproviderId: $careproviderId, input: $input)
      @rest(
        type: "Careprovider"
        path: "careproviders/{args.careproviderId}/dpa"
        method: "POST"
      ) {
        id
        ${fragments.careproviderDpa()}
    }
  }
`;

export const DELETE_CAREPROVIDER_DPA = gql`
  mutation deleteDpaFile($careproviderId: Int!, $input: Any!) {
    deleteDpaFile(careproviderId: $careproviderId, input: $input)
      @rest(
        type: "Careprovider"
        path: "careproviders/{args.careproviderId}/dpa"
        method: "DELETE"
      )
  }
`;

export const GET_CAREPROVIDER_DPA = gql`
  query getCareproviderDPAFile($careproviderId: Int!) {
    file(careproviderId: $careproviderId)
      @rest(
        type: "Careprovider"
        path: "careproviders/{args.careproviderId}/dpa"
        method: "GET"
      ) {
      apistring
    }
  }
`;

export const CREATE_CAREPROVIDER = gql`
  mutation createCareprovider($input: Any!) {
    careprovider(input: $input)
    @rest(type: "Careprovider", path: "careproviders", method: "POST") {
      ${fragments.careprovider()}
    }
  }
`;

export const UPDATE_CAREPROVIDER_TOKEN = gql`
  mutation updateCareprovider($id: Int! $input: Any!, $token: String!) {
    careprovider(id: $id, token: $token, input: $input)
    @rest(type: "Careprovider", path: "careproviders/token/{args.id}?token={args.token}", method: "PUT") {
      ${fragments.careprovider()}
    }
  }
`;

export const UPLOAD_CAREPROVIDER_FILE = gql`
  mutation uploadFile($careproviderId: Int!, $input: Any!) {
    uploadFile(careproviderId: $careproviderId, input: $input)
      @rest(
        type: "CareproviderFile"
        path: "careproviders/{args.careproviderId}/files{args.params}"
        method: "POST"
      ) {
        ${fragments.careproviderFile()}
    }
  }
`;

export const CONNEXT_AUTHENTICATION = gql`
  mutation connextAuthentication($variables: OauthAuthenticationInput!) {
    onpremiseConfig(careproviderId: $careproviderId, variables: $variables)
      @rest(
        type: "ConnextAuthentication"
        path: "careproviders/{args.careproviderId}/integration/{args.integrationId}/auth"
        method: "POST"
      )
  }
`;

// Careseeker Files
export const CREATE_USER_MANAGEMENT_FILE = gql`
  mutation createFile($input: Any!) {
    file(input: $input)
    @rest(type: "SenderUserManagementFile", path: "careseeker/files", method: "POST") {
      ${fragments.userManagementFile}
    }
  }
`;

export const GET_SENDER_USER_MANAGEMENT_FILE = gql`
  query getSenderUserManagementFile($id: Int!) {
    file(id: $id)
      @rest(
        type: "SenderUserManagementFile"
        path: "careseeker/files/{args.id}"
        method: "GET"
      ) {
      url
    }
  }
`;

export const DELETE_USER_MANAGEMENT_FILE = gql`
  mutation deleteFile($id: Int!) {
    deleteFile(id: $id)
      @rest(
        type: "SenderUserManagementFile"
        path: "careseeker/files/{args.id}"
        method: "DELETE"
      ){
        ${fragments.userManagementFile}
      }
  }
`;

// Careprovider Files
export const GET_CAREPROVIDER_FILES = gql`
  query getFiles($careproviderId: Int!) {
    getFiles(careproviderId: $careproviderId)
      @rest(
        type: "CareproviderFileList"
        path: "careproviders/{args.careproviderId}/files{args.params}"
        method: "GET"
      ) {
      images
      documents
    }
  }
`;

export const DELETE_CAREPROVIDER_FILE = gql`
  mutation deleteFile($careproviderId: Int!, $fileId: Int!) {
    deleteFile(careproviderId: $careproviderId, fileId: $fileId)
      @rest(
        type: "CareproviderFile"
        path: "careproviders/{args.careproviderId}/files/{args.fileId}{args.params}"
        method: "DELETE"
      ){
        ${fragments.careproviderFile()}
      }
  }
`;

// File v2
export const GET_FILES = gql`
  query getFiles($params: String!){
    files
    @rest(
      type: "File"
      path: "files?{args.params}"
      method: "GET"
      ){
        ${fragments.filev2()}
      }
  }
  `;

export const CREATE_FILE = gql`
  mutation createFile($input: Any! $params: String!){
    file(input: $input, params:$params)
    @rest(
      type: "File"
      path: "files?{args.params}"
      method: "POST"
    ){
      ${fragments.filev2()}
    }
  }`;

export const UPDATE_FILE = gql`
  mutation updateFile($id: Int! $input: Any! $params: String!){
    file(id: $id, input: $input, params:$params)
    @rest(
      type: "File"
      path: "files/{args.id}?{args.params}"
      method: "PUT"
    ){
      ${fragments.filev2()}
    }
  }`;

export const DELETE_FILE = gql`
  mutation deleteFile($id: Int! $params: String!){
    file(id: $id, params:$params)
    @rest(
      type: "File"
      path: "files/{args.id}?{args.params}"
      method: "DELETE"
    ){
      ${fragments.filev2()}
    }
  }`;

export const GET_SELECTABLE_CAREPROVIDERS = gql`
  query getSelectableCareprovidres($id: Int!){
    careproviders(id: $id)
    @rest(
      type: "Careprovider"
      path: "files/{args.id}/careproviders"
      method: "GET"
    ){
      ${fragments.filev2SelectableCareproviders()}
    }
  }`;

export const GET_CARESEEKER = (args?: {
  withAdminFields?: boolean;
  withConfig?: boolean;
}) => {
  const { withAdminFields, withConfig } = args || {};
  const fields = (() => {
    if (withAdminFields)
      return "?fields=providers,contacts,events,information,config,user_management_file";
    else if (withConfig) return "?fields=config";
    return "";
  })();

  return gql`
    query getCareseeker($id: Int!) {
      careseeker(id: $id)
      @rest(type: "Careseeker", path: "careseekers/{args.id}${fields}") {
        ${fragments.getCareseeker({ withAdminFields, withConfig })}
      }
    }
  `;
};

export const GET_CAREPROVIDER_INFO = gql`
  query getCareproviderInfo($id: Int!) {
    getCareproviderInfo(id: $id)
      @rest(
        type: "CareproviderInfo"
        path: "accounts/{args.id}/careproviderinfo"
      ) {
      careprovider_info
    }
  }
`;

export const GET_ACCOUNT_PATIENT_INFO = gql`
  query getCareseekerPatientInfo($id: Int!) {
    careseekerPatientInfo(id: $id)
      @rest(type: "CareseekerInfo", path: "accounts/{args.id}/patientinfo") {
      careseeker {
        id
      }
      has_patients
    }
  }
`;

export const CREATE_CARESEEKER = gql`
  mutation createCareseeker($input: Any!) {
    careseeker(input: $input)
    @rest(type: "Careseeker", path: "careseekers", method: "POST") {
      ${fragments.careseeker({ withAdminFields: false })}
    }
  }
`;

export const UPDATE_CARESEEKER = gql`
  mutation updateCareseeker($id: Int! $input: Any!) {
    careseeker(id: $id, input: $input)
    @rest(type: "Careseeker", path: "careseekers/{args.id}", method: "PUT") {
      ${fragments.careseeker({ withAdminFields: true })}
    }
  }
`;

export const GET_CARESEEKER_ACCOUNTS = gql`
  query getAccounts($id: Int!) {
    accounts(id: $id)
    @rest(type: "[Account]", path: "roles/careseeker/{args.id}") {
      ${fragments.getAccount({
        withRoles: true,
        withMinimalRoles: true,
        withEncryptionStatus: true,
      })}
    }
  }
`;

export const GET_RECEIVER_ACCOUNTS = gql`
  query getAccounts($id: Int!) {
    accounts(id: $id)
    @rest(type: "[Account]", path: "roles/careprovider/{args.id}") {
      ${fragments.getAccount({
        withRoles: true,
        withMinimalRoles: true,
        withEncryptionStatus: true,
      })}
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query getAccounts {
    accounts
    @rest(type: "[Account]", path: "roles") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const GET_ACCOUNT = gql`
  query getAccount($id: Int!) {
    account(id: $id)
    @rest(type: "Account", path: "accounts/{args.id}") {
      ${fragments.getAccount({ withRoles: true, withEvents: true })}
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($input: Any!) {
    account(input: $input)
    @rest(type: "Account", path: "accounts", method: "POST") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const GET_ACCOUNTS_FOR_CARESEEKER = gql`
  query getAccountsForCareseeker($filters: String!) {
    getAccountsForCareseeker
    @rest(type: "[Account]", path: "careseekers/{args.careseekerId}/usermanagement/accounts?with_pagination&{args.filters}") {
      ${fragments.getAccountWithinCareseekerGroup()}
    }
  }
`;

export const GET_ACCOUNT_WITHIN_CARESEEKER_GROUP = gql`
  mutation getAccountWithinCareseekerGroup($input: Any!) {
    account(input: $input)
    @rest(type: "Account", path: "careseekers/{args.careseekerId}/usermanagement/account", method: "POST") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const CREATE_ACCOUNT_FOR_CARESEEKER = gql`
  mutation createAccountForCareseeker($input: Any!, $careseekerId: String!, $timestamp: String!) {
    account(input: $input, careseekerId: $careseekerId, timestamp: $timestamp)
    @rest(type: "Account", path: "careseekers/{args.careseekerId}/usermanagement/accounts?timestamp={args.timestamp}", method: "POST") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const UPDATE_ACCOUNT_FOR_CARESEEKER = gql`
  mutation updateAccountForCareseeker($accountId: Int!, $input: Any!, $careseekerId: String!, $timestamp: String!) {
    account(accountId: $accountId, input: $input, careseekerId: $careseekerId, timestamp: $timestamp)
    @rest(type: "Account", path: "careseekers/{args.careseekerId}/usermanagement/accounts/{args.accountId}?timestamp={args.timestamp}", method: "PUT") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation createPatient($input: Any!) {
    patient(input: $input)
    @rest(type: "Patient", path: "patients", method: "POST") {
      ${fragments.patient({ minimal: true, withAuctions: true })}
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation updatePatient($id: Int! $input: Any!) {
    patient(id: $id, input: $input)
    @rest(type: "Patient", path: "patients/{args.id}", method: "PUT") {
      ${fragments.patient()}
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($id: Int!, $input: Any!, $jwt: Any) {
    account(id: $id, input: $input, jwt: $token)
    @rest(type: "Account", path: "accounts/{args.id}", method: "PUT") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const UPDATE_ACCOUNT_TOKEN = gql`
  mutation updateAccount($id: Int!, $token: String!, $input: Any!) {
    account(id: $id, token: $token, input: $input)
    @rest(type: "Account", path: "accounts/{args.id}?token={args.token}", method: "PUT") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const GET_PATIENTS_NEW_LIGHT = gql`
  query getPatientsNew($id: Int!, $arguments: String!) {
    patientsNew(id: $id, arguments: $arguments)
      @rest(
        type: "PatientsChunk"
        path: "careseekers/{args.id}/patientsnew?{args.arguments}"
      ) {
      total
      remaining
      patients {
        id
        user_id
        archived
        external_id
        last_action {
          timestamp
          account {
            id
            first_name
            last_name
          }
        }
        patient_type
        station
        session_key_context {
          has_session_keys
          session_key {
            ${fragments.sessionKey()}
          }
        }
        seald_encryption_context {
          ${encryptionContext()}
        }
        station_full {
          ${fragments.station()}
        }
        assignee {
          id
          first_name
          last_name
        }
        careseeker {
          id
          name
        }
        profile {
          age_interval
          gender
          ${fragments.encryptedField("first_name")}
          ${fragments.encryptedField("last_name")}
          ${fragments.encryptedField("birth_date")}
          financing {
            carelevel { level }
            patient_has_health_insurance_state
            ${fragments.encryptedField("insurance_number")}
          }
        }
        diagnosis {
          main_diagnosis
          hospital_stay {
            operation_date
          }
          dependency_diagnosis {
            agitated
            agitated_description
            barthel_assessment
            dependency_level
            early_rehab_assessment
            has_help_at_home
            requires_support_with_feeding
            requires_walking_aid
            returning_home_short_term
            singer_assessment
          }
        }
        auctions {
          id
          status
          start_date
          solutions
          specializations
          new_responses
          action_required_by_user
          share_patient_data_automatically
          seen_by_user
          search_type
          assessment_completed
          profile {
            search_location {
              ${fragments.getLocation({
                withFederalState: true,
              })}
            }
            is_elective
            has_transitional_care
          }
          payers {
            selected_payment_method
            insurance
            public_pension
          }
          winner {
            careprovider {
              id
              name
            }
            unknown_provider
            solution
            specialization
          }
          is_stale
        }
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query getPatient($id: Int!) {
    patient(id: $id)
    @rest(type: "Patient", path: "patients/{args.id}") {
      ${fragments.patient()}
    }
  }
`;

export const GET_EVENTS = (props?: {
  withCounts?: boolean;
  withEventLastSeens?: boolean;
}) => gql`
  query getEvents($params: String!) {
    eventsPayload(params: $params)
    @rest(type: "EventPayload", path: "events?{args.params}") {
      ${
        props?.withCounts
          ? `
      remaining
      total`
          : ""
      }
      events {
        ${fragments.event(props)}
      }
    }
  }
`;

export const GET_AVAILABLE_SEARCH_TYPES = gql`
  query availableSearchTypes($patientId: Int!) {
    getAvailableSearchTypes(patientId: $patientId)
      @rest(
        type: "AvailableSearchTypes"
        path: "auctions/additionalauction/{args.patientId}"
        method: "GET"
      ) {
      search_types
    }
  }
`;

export const CREATE_ADDITIONAL_AUCTION = gql`
  mutation createAdditionalAuction($patientId: Int!, $input: Any!) {
    auction(patientId: $patientId, input: $input)
    @rest(type: "Auction", path: "auctions/additionalauction/{args.patientId}", method: "POST") {
      ${fragments.auction({ withPatient: true })}
    }
  }
`;

export const CREATE_AUCTION = gql`
  mutation createAuction($input: Any!) {
    auction(input: $input)
    @rest(type: "Auction", path: "auctions", method: "POST") {
      ${fragments.auction({ withPatient: true })}
    }
  }
`;

export const UPDATE_ASSOCIATIONS = gql`
  mutation updateAssociations($input: Any!) {
    association(input: $input)
      @rest(
        type: "updateAssociations"
        path: "associations/replace"
        method: "POST"
      ) {
      id
    }
  }
`;

export const GET_SOCIAL_WORKER_ACCOUNTS = gql`
  query getSocialWorkerAccounts($careseekerId: Int!) {
    socialWorkers(careseekerId: $careseekerId)
    @rest(type: "[Account]", path: "careseekers/{args.careseekerId}/accounts") {
      ${fragments.getSocialWorkersAccount()}
    }
  }
`;

export const UPDATE_REQUEST_SESSION_KEYS = gql`
  mutation updateRequestSessionKeys($auctionRequestId: Int!, $input: Any!) {
    sessionKeys(auctionRequestId: $auctionRequestId, input: $input)
    @rest(type: "[SessionKey]", path: "crypto/sessions/{args.auctionRequestId}", method: "PUT") {
      ${fragments.sessionKey()}
    }
  }
`;

export const UPDATE_RECEIVER_SESSION_KEYS = gql`
mutation updateSessionKeys($id: Int!, $input: Any!) {
    sessionKeys(id: $id, input: $input)
    @rest(type: "[SessionKey]", path: "crypto/pending/careprovider/{args.id}", method: "PUT") {
      ${fragments.sessionKey()}
    }
  }
`;

export const CREATE_PENDING_SESSION_KEY = gql`
mutation updateSessionKeys($input: Any!) {
    sessionKeys(input: $input)
    @rest(type: "PendingSessionKey", path: "crypto/pending", method: "POST") {
      ${fragments.pendingSessionKey()}
    }
  }
`;

export const GET_VERSIONS = gql`
  query getVersions {
    versions
    @rest(type: "[Version]", path: "versions") {
      ${fragments.version()}
    }
  }
`;

export const UPDATE_SEARCH_CANDIDATES = gql`
  mutation updateSearchCandidates($input: Any!) {
    searchCandidates(input: $input)
      @rest(
        type: "SearchCandidatesPayload"
        path: "search/auction"
        method: "POST"
      ) {
      services
      count
    }
  }
`;

export const GET_STATIC_SEARCH_CANDIDATES = gql`
  query getStaticSearchCandidates($auctionId: Int!) {
    searchCandidates(auctionId: $auctionId)
      @rest(
        type: "SearchCandidatesPayload"
        path: "search/auction/{args.auctionId}"
        method: "GET"
      ) {
      services
      count
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($endpoint: String!, $input: Any!) {
    forgotPassword(endpoint: $endpoint, input: $input)
      @rest(
        type: "ForgotPasswordPayload"
        path: "{args.endpoint}"
        method: "POST"
      )
  }
`;

export const ARCHIVE_PATIENT = gql`
  mutation archivePatient($id: Int!) {
    patient(id: $id)
      @rest(type: "Patient", path: "patients/{args.id}", method: "DELETE") {
      archived
      id
    }
  }
`;

export const ARCHIVE_PROVIDER_SEARCH_PATIENT = gql`
  mutation archivePatient($id: Int!) {
    patient(id: $id)
      @rest(
        type: "Patient"
        path: "providersearch/patient/{args.id}/archive"
        method: "PUT"
      ) {
      id
      archived
    }
  }
`;

export const GET_ADDRESS = gql`
  mutation getAddress($input: Any!) {
    address(input: $input)
      @rest(type: "AddressPayload", path: "address", method: "POST") {
      ${fragments.getAddress()}
    }
  }
`;

export const GET_ADDRESS_FROM_ZIPCODE = gql`
  query getAddressFromZipcode($zipcode: String!) {
    address(zipcode: $zipcode)
      @rest(type: "AddressPayload", path: "zipcodeaddress/{args.zipcode}") {
      zipcode
      city
      latitude
      longitude
      federal_state
    }
  }
`;

export const ZIPCODE_INSIDE_CATCHMENT = gql`
  query isInsideCatchment($careproviderId: Int!, $zipcode: String!) {
    catchment(careproviderId: $careproviderId, zipcode: $zipcode)
      @rest(
        type: "ProviderSearchCatchmentArea"
        path: "providersearch/careprovider/{args.careproviderId}/zipcode/{args.zipcode}"
      ) {
      inside_catchment
    }
  }
`;

export const ADD_CAREPROVIDER_MEMBER = ({ invite }: { invite: boolean }) => gql`
  mutation addCareproviderMember($id: Int!, $input: Any!) {
    careproviderMember(id: $id, input: $input)
      @rest(type: "Careprovider", path: "careproviders/{args.id}/accounts${
        invite ? "?invite" : ""
      }", method: "POST") {
        ${fragments.getCareprovider()}
    }
  }
`;

export const ADD_CAREPROVIDER_MEMBER_TOKEN = ({
  invite,
}: {
  invite: boolean;
}) => gql`
  mutation addCareproviderMember($id: Int!, $token: String!, $input: Any!) {
    careproviderMember(id: $id, token: $token, input: $input)
      @rest(type: "Careprovider", path: "careproviders/{args.id}/accounts?token={args.token}${
        invite ? "&invite" : ""
      }", method: "POST") {
        ${fragments.getCareprovider()}
    }
  }
`;

export const CAREPROVIDER_ACCOUNT_ASSOCIATION = gql`
  mutation careproviderAccountAssociation($id: Int!, $token: String!, $input: Any!) {
    accountAssociation(id: $id, token: $token, input: $input)
      @rest(type: "Careprovider", path: "careproviders/{args.id}/accounts/associate?token={args.token}", method: "POST") {
        ${fragments.getCareprovider()}
    }
  }
`;

export const UPDATE_CAREPROVIDER_ACCOUNT = gql`
  mutation updateCareproviderAccount($careproviderId: Int!, $accountId: Int!, $input: Any!) {
    careproviderAccount(careproviderId: $careproviderId, accountId: $accountId, input: $input)
    @rest(type: "Account", path: "careproviders/{args.careproviderId}/accounts/{args.accountId}", method: "PUT") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const UPDATE_CAREPROVIDER_ACCOUNT_TOKEN = gql`
  mutation updateCareproviderAccount($careproviderId: Int!, $accountId: Int!, $input: Any!, $token: String!) {
    careproviderAccount(careproviderId: $careproviderId, accountId: $accountId, input: $input, token: $token)
    @rest(type: "Account", path: "careproviders/{args.careproviderId}/accounts/{args.accountId}?token={args.token}", method: "PUT") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const ADD_ACCOUNT_ROLES = gql`
  mutation addAccountRole($input: Any!) {
    accountRole(input: $input)
    @rest(type: "Account", path: "roles", method: "POST") {
      ${fragments.getAccount({ withRoles: true, withEncryptionStatus: true })}
    }
  }
`;

export const DELETE_ACCOUNT_ROLE = gql`
  mutation deleteAccountRole($accountId: Int!, $role_value: Int!, $queryVars: String, $input: Any!) {
    accountRole(accountId: $accountId, role_value: $role_value, queryVars: $queryVars, input: $input)
    @rest(type: "Account", path: "role/{args.accountId}?role_value={args.role_value}&{args.queryVars}", method: "DELETE") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const ADD_CAREPROVIDER_ACCOUNT_ROLES = gql`
  mutation addCareproviderAccountRole($careproviderId: Int!, $input: Any!) {
    careproviderAccountRole(careproviderId: $careproviderId, input: $input)
    @rest(type: "Account", path: "careproviders/{args.careproviderId}/roles", method: "POST") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const DELETE_CAREPROVIDER_ACCOUNT_ROLE = gql`
  mutation deleteCareproviderAccountRole($careproviderId: Int!, $accountId: Int!, $role_value: Int!, $queryVars: String, $input: Any!) {
    careproviderAccountRole(careproviderId: $careproviderId, accountId: $accountId, role_value: $role_value,  queryVars: $queryVars,input: $input)
    @rest(type: "Account", path: "careproviders/{args.careproviderId}/role/{args.accountId}?role_value={args.role_value}&{args.queryVars}", method: "DELETE") {
      ${fragments.getAccount({ withRoles: true })}
    }
  }
`;

export const GET_CAREPROVIDER_WARNINGS = gql`
  query getCareproviderWarnings($auctionId: Int!, $careproviderId: Int!) {
    careproviderWarnings(
      auctionId: $auctionId
      careproviderId: $careproviderId
    )
      @rest(
        type: "[CareproviderWarningsPayload]"
        path: "auctions/{args.auctionId}/filters/{args.careproviderId}"
      ) {
      auction_request
      solution_filter_reasons
      careprovider
    }
  }
`;

export const CREATE_ACCOUNTS_BATCH = gql`
  mutation createAccounts($input: Any!) {
    accounts(input: $input)
      @rest(type: "[Account]", path: "accounts/batch", method: "POST") {
        ${fragments.getAccount({ withRoles: true })}
      }
  }
`;

export const GET_TRANSLATIONS = gql`
  query getTranslations {
    translations
      @rest(type: "[Translation]", path: "translations", method: "GET") {
        ${fragments.translation()}
      }
  }
`;

export const CREATE_TRANSLATIONS = gql`
  mutation createTranslations($input: Any!) {
    translations(input: $input)
      @rest(type: "[Translation]", path: "translations", method: "POST") {
        ${fragments.translation()}
      }
  }
`;

export const GET_TRANSLATION = gql`
  query getTranslation($id: String!) {
    translation(id: $id)
      @rest(type: "Translation", path: "translation/{args.id}", method: "GET") {
        ${fragments.translation()}
      }
  }
`;

export const UPDATE_TRANSLATION = gql`
  mutation updateTranslation($input: Any!, $id: String!) {
    translation(id: $id, input: $input)
      @rest(type: "Translation", path: "translation/{args.id}", method: "PUT") {
        ${fragments.translation()}
      }
  }
`;

export const CREATE_TRANSLATION = gql`
  mutation createTranslation($input: Any!) {
    translation(input: $input)
      @rest(type: "Translation", path: "translation", method: "POST") {
        ${fragments.translation()}
      }
  }
`;

export const DELETE_TRANSLATION = gql`
  mutation deleteTranslation($id: String!, $input: Any!) {
    translation(id: $id, input: $input)
      @rest(type: "Translation", path: "translation/{args.id}", method: "DELETE") {
        ${fragments.translation()}
      }
  }
`;

export const NOTIFY_SOCIAL_WORKER = gql`
  mutation notifySocialWorker($id: Int!, $input: Any!) {
    notifySocialWorker(id: $id, input: $input)
      @rest(
        type: "NotifySocialWorker"
        path: "patients/{args.id}/notify"
        method: "POST"
      ) {
      message
    }
  }
`;

export const NOTIFY_RECEIVER_RESPONSIBLE_PERSON = gql`
  mutation notifyReceiverAssignee($id: Int!, $input: Any!) {
    notifySocialWorker(id: $id, input: $input)
      @rest(
        type: "NotifyReceiverAssignee"
        path: "requests/{args.id}/notify"
        method: "PUT"
      ) {
      message
    }
  }
`;

export const BATCH_ADD_PROVIDER_ACCOUNTS = gql`
  mutation batchAddProviderAccounts($id: Int!, $input: Any!) {
    providerAccounts(id: $id, input: $input)
      @rest(type: "Careprovider", path: "careproviders/{args.id}/accounts/batch", method: "POST") {
        ${fragments.getCareprovider()}
      }
  }
`;

export const ADD_PRIORITY_PROVIDER = gql`
  mutation addPriorityProvider($careseekerId: Int!, $input: Any!) {
    careseeker(careseekerId: $careseekerId, input: $input)
      @rest(
        type: "Careseeker"
        path: "careseekers/{args.careseekerId}/careproviders/{args.input}"
        method: "POST"
      ) {
        ${fragments.getCareseeker({
          withAdminFields: false,
          withConfig: false,
        })}
    }
  }
`;

export const DELETE_PRIORITY_PROVIDER = gql`
  mutation deletePriorityProvider($careseekerId: Int!, $careproviderId: Int!) {
    careseeker(careseekerId: $careseekerId, careproviderId: $careproviderId)
      @rest(
        type: "Careseeker"
        path: "careseekers/{args.careseekerId}/careproviders/{args.careproviderId}"
        method: "DELETE"
      ) {
        ${fragments.getCareseeker({
          withAdminFields: true,
          withConfig: false,
        })}
    }
  }
`;

export const GET_AUCTION = gql`
  query getAuction($auctionId: Int!) {
    auction(auctionId: $auctionId)
      @rest(type: "Auction", path: "auctions/{args.auctionId}?selective", method: "GET") {
        ${fragments.auction({ withPatient: true })}
      }
  }
`;

export const GET_DYNAMIC_AUCTION_FORM_ALL_FIELDS = gql`
  mutation getAuctionFormAllFields($input: Any!) {
    auctionFormAllFields(input: $input)
      @rest(
        type: "[String]"
        path: "formconfiguration/dynamic/fields"
        method: "POST"
      )
  }
`;

export const GET_DYNAMIC_AUCTION_ASSESSMENT_STEPS = gql`
  mutation getAuctionAssessmentSteps($revision: Int!, $input: Any!) {
    formStepsConfiguration(input: $input, revision: $revision)
      @rest(
        type: "[String]"
        path: "formconfiguration/dynamic/steps/test?revision={args.revision}"
        method: "POST"
      )
  }
`;

export const GET_DYNAMIC_AUCTION_FORM_CONFIGURATION = gql`
  mutation getAuctionStepFields(
    $formName: String!
    $revision: Int!
    $input: Any!
  ) {
    auctionFormConfiguration(
      formName: $formName
      revision: $revision
      input: $input
    )
      @rest(
        type: "[String]"
        path: "formconfiguration/dynamic/fields/{args.formName}?revision={args.revision}"
        method: "POST"
      )
  }
`;

export const GET_SENDER_RECEIVER_RULES = gql`
  query getSenderReceiverRules($params: String!) {
    senderReceiverRules(params: $params)
      @rest(type: "[SenderReceiverRule]", path: "associations{args.params}", method: "GET") {
        ${fragments.SenderReceiverRules()}
    }
  }
`;

export const GET_ONPREMISE_CONFIG = gql`
  query getOnpremiseConfig($configid: Int!) {
    onpremiseConfig(configId: $configId)
      @rest(type: "OnpremiseConfig", path: "onpremise/config/{args.configId}", method: "GET") {
        ${fragments.onPremiseConfig()}
    }
  }
`;

export const GET_ONPREMISE_CONFIG_FOR_CARESEEKER = gql`
  query getOnpremiseConfigForCareseeker($configid: Int!) {
    onpremiseConfig(configId: $configId)
      @rest(type: "OnpremiseConfig", path: "onpremise/careseeker/{args.careseekerId}/config", method: "GET") {
        ${fragments.onPremiseConfig()}
    }
  }
`;

export const CREATE_ONPREMISE_CONFIG = gql`
  mutation createOnpremiseConfig($config: OnpremiseConfigInput!) {
    onpremiseConfig(config: $config)
      @rest(type: "OnpremiseConfig", path: "onpremise/config/create", method: "POST") {
        ${fragments.onPremiseConfig()}
      }
    }
  `;

export const CREATE_ONPREMISE_IMPORT = gql`
  mutation createOnpremiseImport($config: OnpremiseConfigInput!) {
    onpremiseConfig(config: $config)
      @rest(type: "OnpremiseConfig", path: "onpremise/careseeker/{args.careseekerId}/import", method: "POST") {
        ${fragments.onPremiseConfigImport()}
      }
    }
  `;

export const GET_ONPREMISE_IMPORTS = gql`
  query getOnpremiseImports($careseekerId: Int!) {
    onpremiseConfig(careseekerId: $careseekerId)
      @rest(
        type: "OnpremiseConfig"
        path: "onpremise/careseeker/{args.careseekerId}/imports"
      ) {
        ${fragments.onPremiseConfigImport()}
    }
  }
`;

export const UPDATE_ONPREMISE_CONFIG = gql`
  mutation updateOnpremiseConfig($configId: Int!, $config: OnpremiseConfigInput!) {
    onpremiseConfig(config: $config, configid: $configid)
      @rest(type: "OnpremiseConfig", path: "onpremise/config/{args.configId}/update", method: "PUT") {
        ${fragments.onPremiseConfig()}
      }
    }
  `;

export const GET_ONPREMISE_BUNDLE_LINK = gql`
  query getBundleDownloadLink($configId: Int!) {
    link(configId: $configId)
      @rest(
        type: "BundleLinkPayload"
        path: "onpremise/config/{args.configId}/bundle"
      ) {
      apistring
    }
  }
`;

export const GET_ONPREMISE_BUNDLE_LINK_CARESEEKER = gql`
  query getBundleDownloadLink($careseekerId: Int!) {
    link(careseekerId: $careseekerId)
      @rest(
        type: "BundleLinkPayload"
        path: "onpremise/careseeker/{args.careseekerId}/bundle"
      ) {
      apistring
    }
  }
`;

export const FEEDBACK_PATIENT_INFO_MISSING = gql`
  mutation updateFeedbackPatientInfoMising(
    $input: Any!
    $careproviderId: Int!
    $auctionRequestId: Int!
  ) {
    feedbackPatientInfoMising(
      input: $input
      careproviderId: $careproviderId
      auctionRequestId: $auctionRequestId
    )
      @rest(
        type: "Feedback"
        path: "careproviders/{args.careproviderId}/missing_info/{args.auctionRequestId}"
        method: "POST"
      ) {
      feedback
    }
  }
`;

export const GET_SENDER_RECEIVER_NOTE = gql`
  query getSenderReceiverNote($careseekerId: Int!, $careproviderId: Int!) {
    senderReceiverNote(
      careseekerId: $careseekerId
      careproviderId: $careproviderId
    )
      @rest(
        type: "SenderReceiverNote"
        path: "careseekers/{args.careseekerId}/notes/{args.careproviderId}"
        method: "GET"
      ) {
        ${fragments.senderReceiverNote()}
    }
  }
`;

export const UPDATE_SENDER_RECEIVER_NOTE = gql`
  mutation updateSenderReceiverNote(
    $careseekerId: Int!
    $careproviderId: Int!
    $input: Any!
  ) {
    senderReceiverNote(
      careseekerId: $careseekerId
      careproviderId: $careproviderId
      input: $input
    )
      @rest(
        type: "SenderReceiverNote"
        path: "careseekers/{args.careseekerId}/notes/{args.careproviderId}"
        method: "PUT"
      ) {
        ${fragments.senderReceiverNote()}
    }
  }
`;

export const GET_PATIENT_BY_EXTERNAL_ID = gql`
  query getPatientsByExternalId($careseekerId: Int!, $externalId: String!) {
    patientByExternalId(careseekerId: $careseekerId, externalId: $externalId)
      @rest(
        type: "PatientsByExternalId",
        path: "careseekers/{args.careseekerId}/patients/case/{args.externalId}",
        method: "GET"
      ) {
        ${fragments.patient()}
      }
  }
`;

export const GET_CAREPROVIDER_NOTIFICATIONS = gql`
  query getCareproviderNotifications($start: Int!, $count: Int!) {
    careproviderNotifications(start: $start, count: $count)
    @rest(
      type: "CareproviderNotifications",
      path: "notifications?start={args.start}&count={args.count}",
      method: "GET"
    ) {
      events {
        ${fragments.notificationEvent()}
      }
      total
      remaining
    }
  }
`;

export const USE_REQUEST_ACTION = (
  actionType: RequestActionType | UndoActionType,
) => gql`
  mutation useRequestAction($id: Int!, $input: Any!) {
    requestAction(id: $id, input: $input)
      @rest(
        type: "AuctionRequest"
        path: "action/request/{args.id}/${actionType}"
        method: "POST"
      ) {
      ${requestActionFragments[actionType]}
    }
  }
`;

export const USE_SEARCH_ACTION = (actionType: SearchActionsWithHandlers) => gql`
  mutation useSearchAction($auctionId: Int!, $input: Any!) {
    auction(auctionId: $auctionId, input: $input)
      @rest(
        type: "Auction"
        path: "action/search/{args.auctionId}/${actionType}"
        method: "POST"
      ) {
      ${searchActionFragments[actionType]}
    }
  }
`;

export const ON_PENDING_SEALD_ACCESS = gql`
  subscription OnPendingSealdAccess {
    pendingSealdAccess @rest(type: "PendingSealdAccess") {
      account_id
      careprovider_id
      careseeker_id
      created_at
      id
      seald_encryption_context {
        ${encryptionContext()}
      }
    }
  }
`;

export const ON_REFRESH_SEARCH_MERGE = gql`
  subscription OnRefreshSearchMerge($auctionId: Int!) {
    refreshSearchMerge(auctionId: $auctionId)
      @rest(type: "RefreshSearchMergePayload") {
      full_page_refresh
    }
  }
`;

export const ON_ENCRYPTION_JOB = gql`
  subscription OnEncryptionJob {
    encryptionJob @rest(type: "EncryptionJob") {
      ${fragments.encryptionJob()}
    }
  }
`;

export const ON_REQUEST_STATUS_CHANGED = gql`
  subscription OnRequestStatusChanged {
    requestStatusChanged @rest(type: "AuctionRequestStatusChanged") {
      should_refetch
    }
  }
`;

export const ON_CANDIDATES_CALCULATED = gql`
  subscription OnAuctionCandidatesComplete {
    auctionCandidatesComplete @rest(type: "Auction") {
      id
      candidates_status
    }
  }
`;

export const ON_REQUEST_EVENT_RECEIVED = gql`
  subscription OnRequestEventReceived {
    requestEventReceived @rest(type: "RefreshMessengerPayload") {
      new_event_received
    }
  }
`;

export const POST_ENCRYPTION_JOB = gql`
  mutation postEncryptionJob($id: Int!, $auctionId: Int!, $input: Any!) {
    encryptionJob(id: $id, auctionId: $auctionId, input: $input)
      @rest(
        type: "EncryptionJobOutput"
        path: "auctions/{args.auctionId}/encryptionjob/{args.id}"
        method: "PUT"
      )
  }
`;

export const CREATE_ENCRYPTION_CONTEXT = gql`
  mutation createEncryptionContext($input: Any!) {
    encryptionContext(input: $input)
      @rest(
        type: "EncryptionContext"
        path: "seald/encryptioncontext"
        method: "POST"
      ) {
        ${fragments.encryptionContext()}
      }
  }
`;

export const INITIATE_SEALD_IDENTITY = gql`
  mutation initiateSealdIdentity($datetime: String!, $input: Any!) {
    sealdIdentity(datetime: $datetime, input: $input)
      @rest(
        type: "SealdIdentityPayload"
        path: "seald/initiateidentity?{args.datetime}"
        method: "POST"
      ) {
        ${fragments.sealdIdentityPayload()}
    }
  }
`;

export const DELETE_SEALD_ACCESS = gql`
  mutation deleteSealdAccess($input: Any!) {
    sealdIdentity(input: $input)
      @rest(
        type: "SealdAccessPayload"
        path: "seald/access/delete"
        method: "POST"
      )
  }
`;

export const UPDATE_BROWSER_SUBSCRIPTION = gql`
  mutation updateBrowserSubscription($id: Int!, $input: Any!) {
    browserSubscription(id: $id, input: $input)
      @rest(
        type: "BrowserSubscription"
        path: "accounts/{args.id}/browsersubscription"
        method: "PUT"
      ) {
      id
    }
  }
`;

export const START_ELECTIVE_FLOW = gql`
  mutation startElectiveFlow($input: Any) {
    electiveFlow(input: $input)
      @rest(type: "ElectiveFlow", path: "auctions/elective", method: "POST")
  }
`;

export const CREATE_CAREPROVIDER_REVERSE_SEARCH = gql`
  mutation createReverseSearch($careproviderId: Int!, $input: Any!) {
    reverseSearch(careproviderId: $careproviderId, input: $input)
      @rest(
        type: "ReverseSearch"
        path: "careproviders/{args.careproviderId}/reversesearch"
        method: "POST") {
        ${fragments.reverseSearches()}
    }
  }
`;

export const GET_CAREPROVIDER_REVERSE_SEARCHES = gql`
  query getReverseSearches($careproviderId: Int!) {
    reverseSearch(careproviderId: $careproviderId)
      @rest(
        type: "ReverseSearch"
        path: "careproviders/{args.careproviderId}/reversesearch"
        method: "GET"
      ) {
    reverse_searches {
        ${fragments.reverseSearches()}
    }
}
  }
`;

export const DELETE_CAREPROVIDER_REVERSE_SEARCH = gql`
  mutation deleteReverseSearch($searchId: Int!, $careproviderId: Int!) {
    deleteReverseSearch(searchId: $searchId, careproviderId: $careproviderId)
      @rest(
        type: "ReverseSearch"
        path: "careproviders/{args.careproviderId}/reversesearch/{args.searchId}"
        method: "DELETE"
      )
  }
`;

export const UPDATE_CAREPROVIDER_REVERSE_SEARCH = gql`
  mutation updateReverseSearch($searchId: Int!, $careproviderId: Int!) {
    updateReverseSearch(searchId: $searchId, careproviderId: $careproviderId)
      @rest(
        type: "ReverseSearch"
        path: "careproviders/{args.careproviderId}/reversesearch/{args.searchId}"
        method: "PUT") {
          ${fragments.reverseSearches()}
    }
  }
`;

export const GET_CAREPROVIDER_PATIENT_REQUEST = gql`
  query getProviderPatientRequest($careproviderId: Int!, $patientId: Int!) {
    providerPatientRequest(
      careproviderId: $careproviderId
      patientId: $patientId
    )
      @rest(
        type: "ProviderPatientRequest"
        path: "careprovider/{args.careproviderId}/patient/{args.patientId}"
        method: "GET"
      ) {
      id
    }
  }
`;

export const GET_SEARCH_MERGE_REQUESTS = (
  requestedSections: {
    [index in SearchTable]?: boolean;
  } & { context?: boolean },
) => gql`
query getSearchMergeRequests($auctionId: Int!, $queryParams: string!) {
  searchMergeRequests(auctionId: $auctionId, queryParams: $queryParams)
    @rest(
      type: "SearchMerge"
      path: "searchmerge/{args.auctionId}?{args.queryParams}${
        requestedSections?.context ? "&with_context" : ""
      }"
      method: "GET") {
        ${searchMergeFragments.searchMergePayload(requestedSections)}
    }
  }
`;

// FE cache only
export const GET_SEARCH_MERGE_TOTALS = gql`
  query getSearchMergeTableTotals($auctionId: Int!) {
    searchMergeTableTotals(auctionId: $auctionId)
      @rest(
        type: "SearchMerge"
        path: "searchmerge/{args.auctionId}?table_totals"
        method: "GET"
      ) {
      accepted_requests
      potential_candidates
      contacted_requests
      declined_requests
      filtered_candidates
      rejected_requests
      validated_request
    }
  }
`;

export const GET_SEARCH_MERGE_MAP_REQUESTS = gql`
  query getSearchMergeResultsForMap($auctionId: Int!) {
    requests(auctionId: $auctionId)
      @rest(
        type: "SearchMergeMapResults"
        path: "searchmerge/map/{args.auctionId}"
      ) {
      id
      status
      careprovider {
        name
        patient_type
        address {
          longitude
          latitude
        }
      }
    }
  }
`;

export const GET_PRODUCT_TOUR = gql`
  query getProductTour($name: String!) {
    productTour(name: $name)
      @rest(
        type: "ProductTourCompletion"
        path: "tours?name={args.name}"
        method: "GET"
      ) {
      product_tour_id
      completed
    }
  }
`;

export const COMPLETE_PRODUCT_TOUR = gql`
  mutation completeProductTour($tourId: Int!, $input: Any!) {
    productTour(tourId: $tourId, input: $input)
      @rest(
        type: "ProductTourCompletion"
        path: "tours/{args.tourId}/complete"
        method: "POST"
      ) {
      product_tour_id
      completed
    }
  }
`;

export const GET_ENCRYPTION_JOBS = gql`
  query getEncryptionJobs($careseekerId: Int!) {
    encryptionJobs(careseekerId: $careseekerId)
    @rest(type: "[EncryptionJobPayload]", path: "encryptionjobs?careseeker_id={args.careseekerId}") {
      ${fragments.encryptionJob()}
    }
  }
`;

export const CHECK_SEALD_SESSIONS = gql`
  mutation checkSealdSessions($input: Any!) {
    sealdSessions(input: $input)
      @rest(
        type: "SealdSessionsPayload"
        path: "seald/session/check"
        method: "POST"
      ) {
      ${fragments.sessionAccesses()}
    }
  }
`;

export const SEALD_GROUP_RESET = gql`
  mutation sealdGroupReset($input: Any!) {
    sealdGroup(input: $input)
      @rest(
        type: "SealdGroupReset"
        path: "seald/group/reset?renew"
        method: "POST"
      )
  }
`;

export const SEALD_GROUP_SESSIONS_CHECK = gql`
  query sealdGroupSessionsCheck($sealdId: String!) {
    sealdGroupSessions(sealdId: $sealdId)
      @rest(
        type: "GroupSessionsResponse"
        path: "seald/group/{args.sealdId}/sessions"
        method: "GET"
      ) {
        ${fragments.groupSessionsResponse}
      }
  }
`;

export const GET_PROVIDER_SEARCH_PATIENTS = gql`
  query getProviderSearchPatients {
    patients
      @rest(
        type: "ProviderSearchPatients"
        path: "providersearch/patients"
        method: "GET"
      ) {
      active {
        ${fragments.providerSearchPatient(true)}
      }
      archived {
        ${fragments.providerSearchPatient(false)}

      }
    }
  }
`;

export const GET_PROVIDER_SEARCH_CONSULTANT = gql`
  query getConsultant($id: Int!, $queryParams: String!) {
    consultant(id: $id, queryParams: $queryParams)
      @rest(
        type: "ProviderSearchProvider"
        path: "providersearch/consultant/{args.id}?{args.queryParams}"
        method: "GET"
      ) {
      ${fragments.providerSearchProvider}
    }
  }
`;

export const GET_PROVIDER_SEARCH_PROVIDER = gql`
  query getProviderSearchProvider($id: Int!, $queryParams: String!) {
    providerSearchProvider(id: $id, queryParams: $queryParams)
      @rest(
        type: "ProviderSearchProvider"
        path: "providersearch/careprovider/{args.id}?{args.queryParams}"
        method: "GET"
      ) {
      ${fragments.providerSearchProvider}
    }
  }
`;

export const GET_PROVIDER_SEARCH_REQUEST_OVERVIEW = gql`
  query getProviderSearchRequestOverview($patientId: Int!) {
    providerSearchRequestOverview(patientId: $patientId)
      @rest(
        type: "ProviderSearchRequestOverview"
        path: "providersearch/patient/{args.patientId}"
        method: "GET"
      ) {${fragments.providerSearchRequestOverview}
    }
  }
`;

export const CREATE_PROVIDER_SEARCH_ACCOUNT = gql`
  mutation createProviderSearchAccount($input: Any!, $redirectTo: String!) {
    account(input: $input, redirectTo: $redirectTo)
      @rest(
        type: "Account"
        path: "providersearch/account/create?${REDIRECT_PARAM}={args.redirectTo}"
        method: "POST"
      )
  }
`;

export const LOGIN_PROVIDER_SEARCH_ACCOUNT = gql`
  mutation loginProviderSearchAccount($input: Any!, $redirectTo: String!) {
    account(input: $input, redirectTo: $redirectTo)
      @rest(
        type: "Account"
        path: "providersearch/account/login?${REDIRECT_PARAM}={args.redirectTo}"
        method: "POST"
      )
  }
`;

export const ACTIVATE_PROVIDER_SEARCH_ACCOUNT = gql`
  query activateProviderSearch($token: string!) {
    activateProviderSearch(token: $token)
      @rest(
        type: "Account"
        path: "providersearch/account/create/activation/{args.token}"
        method: "GET"
      ) {
      account
      auth_result {
        id_token
        account_id
      }
      seald_two_man_rule_session_id
    }
  }
`;

export const LOGIN_ACTIVATION_PROVIDER_SEARCH_ACCOUNT = gql`
  query loginProviderSearch($token: string!) {
    loginProviderSearch(token: $token)
      @rest(
        type: "Account"
        path: "providersearch/account/login/activation/{args.token}"
        method: "GET"
      ) {
      account
      auth_result {
        id_token
        account_id
      }
      seald_two_man_rule_session_id
    }
  }
`;

export const CREATE_PROVIDERSEARCH_CARESEEKER = gql`
  mutation createProviderSearchCareseeker($input: Any!) {
    careseeker(input: $input)
    @rest(type: "Careseeker", path: "providersearch/careseeker", method: "POST") {
      ${fragments.getCareseeker({ withAdminFields: true, withConfig: false })}
    }
  }
`;

export const GET_PROVIDER_SEARCH_LISTING = gql`
  query getProviderSearchListing($queryParams: String!) {
    providerSearchListing(queryParams: $queryParams)
      @rest(
        type: "providerSearchListing"
        path: "providersearch/listing?{args.queryParams}"
      ) {
        ${fragments.providerListing}
    }
  }
`;

export const GET_CONSULTANT = gql`
  query getConsultant($id: Int!) {
    consultant(id: $id)
      @rest(
        type: "Consultant"
        path: "consultants/{args.id}"
        method: "GET"
      ) {
      ${fragments.acpConsultant}
    }
  }
`;

export const UPDATE_CONSULTANT = gql`
  mutation updateConsultant($id: Int! $input: Any!) {
    consultant(id: $id, input: $input)
    @rest(type: "Consultant", path: "consultants/{args.id}", method: "PUT") {
      ${fragments.acpConsultant}
    }
  }
`;

export const GET_CONSULTANT_FILES = gql`
  query getFiles($consultantId: Int!) {
    getFiles(consultantId: $consultantId)
      @rest(
        type: "ConsultantFileList"
        path: "consultants/{args.consultantId}/files"
        method: "GET"
      ) {
      images
    }
  }
`;

export const DELETE_CONSULTANT_FILE = gql`
  mutation deleteFile($consultantId: Int!, $fileId: Int!) {
    deleteFile(consultantId: $consultantId, fileId: $fileId)
      @rest(
        type: "CareproviderFile"
        path: "consultants/{args.consultantId}/files/{args.fileId}"
        method: "DELETE"
      ){
        ${fragments.careproviderFile()}
      }
  }
`;

export const UPLOAD_CONSULTANT_FILE = gql`
  mutation uploadFile($consultantId: Int!, $input: Any!) {
    uploadFile(consultantId: $consultantId, input: $input)
      @rest(
        type: "CareproviderFile"
        path: "consultants/{args.consultantId}/files"
        method: "POST"
      ) {
        ${fragments.careproviderFile()}
      }
  }
`;

export const CREATE_CONSULTANT = gql`
  mutation createConsultant($input: Any!) {
    consultant(input: $input)
    @rest(type: "Consultant", path: "consultants", method: "POST") {
      ${fragments.acpConsultant}
    }
  }
`;

export const GET_CONSULTANT_EVENTS = gql`
  query getConsultantEventPage($id: Int!, $queryParams: string!) {
    eventPage(id: $id, queryParams: $queryParams)
      @rest(
        type: "EventPage"
        path: "consultants/{args.id}/events?{args.queryParams}"
        method: "GET"
      ) {
      events {
       id
       type
       account {
        ${account()}
       }
       context {
        diff
       }
       timestamp
      }
      total
      remaining
    }
  }
`;

export const GET_EMAIL_TRANSLATIONS = gql`
  query getEmailTranslations {
    translations
      @rest(type: "[EmailTranslation]", path: "emailtranslations", method: "GET") {
        ${fragments.emailTranslation}
      }
  }
`;

export const GET_EMAIL_TRANSLATION = gql`
  query getEmailTranslation($id: String!) {
    translation(id: $id)
      @rest(type: "EmailTranslation", path: "emailtranslation/{args.id}", method: "GET") {
        ${fragments.emailTranslation}
      }
  }
`;

export const UPDATE_EMAIL_TRANSLATION = gql`
  mutation updateEmailTranslation($input: Any!, $id: String!) {
    translation(id: $id, input: $input)
      @rest(type: "EmailTranslation", path: "emailtranslation/{args.id}", method: "PUT") {
        ${fragments.emailTranslation}
      }
  }
`;

export const CREATE_EMAIL_TRANSLATION = gql`
  mutation createEmailTranslation($input: Any!) {
    translation(input: $input)
      @rest(type: "EmailTranslation", path: "emailtranslation", method: "POST") {
        ${fragments.emailTranslation}
      }
  }
`;

export const DELETE_EMAIL_TRANSLATION = gql`
  mutation deleteEmailTranslation($id: String!, $input: Any!) {
    translation(id: $id, input: $input)
      @rest(type: "EmailTranslation", path: "emailtranslation/{args.id}", method: "DELETE") {
        ${fragments.emailTranslation}
      }
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation sendTestEmail($input: Any!) {
    testEmail(input: $input)
      @rest(type: "SendEmail", path: "email/send", method: "POST")
  }
`;

export const GET_LEGAL_DOCUMENTS = gql`
  query getLegalDocuments($params: String!) {
    legalDocuments(params: $params)
      @rest(
        type: "LegalDocuments"
        path: "legaldocuments{args.params}"
        method: "GET"
      ) {
        ${fragments.legalDocuments}
    }
  }
`;

export const UPDATE_CAPACITY = gql`
  mutation updateCapacity($id: Int! $input: Any!) {
    capacity(id: $id, input: $input)
    @rest(type: "CareproviderCapacity", path: "careproviders/{args.id}/capacity", method: "PUT") {
      ${fragments.careproviderCapacity}
    }
  }
`;

export const GET_TRANSITIONAL_CARE = gql`
  query getTransitionalCare($auctionId: Int! $patientId: Int!) {
    auction(auctionId: $auctionId, patientId: $patientId)
      @rest(type: "Auction", path: "auctions/transitionalcare/{args.patientId}/{args.auctionId}", method: "GET") {
        ${fragments.transitionalCare}
      }
  }
`;

export const GET_TRANSITIONAL_CARE_REQUESTS = gql`
  query getTransitionalCareRequests($auctionId: Int!) {
    transitionalCareRequests(auctionId: $auctionId)
      @rest(
        type: "[AuctionRequest]"
        path: "auctions/{args.auctionId}/transitionalcare"
        method: "GET"
      ) {
      id
      request_status
      created_at
      careprovider {
        id
        name
        address {
          address
          city
          zip_code
        }
      }
    }
  }
`;

export const GET_REHAB_FORMS = gql`
  query getRehabForms($auctionId: Int! $patientId: Int!) {
    auction(auctionId: $auctionId, patientId: $patientId)
      @rest(type: "Auction", path: "auctions/rehabforms/{args.patientId}/{args.auctionId}", method: "GET") {
        ${fragments.rehabForms}
      }
  }
`;

export const GET_BEDROCK_PLAYGROUND = gql`
  query getBedrockPlayground {
    bedrockPlayground
    @rest(type: "[BedrockPlayground]", path: "bedrockplayground") {
       ${fragments.bedrockPlayground}
    }
  }
`;

export const SAVE_BEDROCK_PROMPT = gql`
  mutation savePrompt($id: Int!, $input: Any!) {
    prompt(input: $input)
    @rest(type: "BedrockPrompt", path: "bedrockplayground/prompt/replace", method: "POST") {
      ${fragments.prompt}
    }
  }
`;

export const SAVE_BEDROCK_SCHEMAS = gql`
  mutation saveSchema($id: Int!, $input: Any!) {
    schema(input: $input)
    @rest(type: "BedrockSchema", path: "bedrockplayground/schema/replace", method: "POST") {
      ${fragments.schema}
    }
  }
`;

export const SAVE_BEDROCK_EXAMPLE = gql`
  mutation saveExample($id: Int!, $input: Any!) {
    example(input: $input)
    @rest(type: "BedrockExample", path: "bedrockplayground/example/replace", method: "POST") {
      ${fragments.bedrockExample}
    }
  }
`;

export const SAVE_BEDROCK_RESPONSE = gql`
  mutation saveResponse($id: Int!, $input: Any!) {
    response(input: $input)
    @rest(type: "BedrockResponse", path: "bedrockplayground/response", method: "POST") {
      ${fragments.bedrockResponse}
    }
  }
`;

export const DELETE_BEDROCK_PROMPT = gql`
  mutation deletePrompt($id: Int!) {
    deletePrompt(id: $id)
      @rest(
        type: "BedrockPrompt"
        path: "bedrockplayground/prompt/{args.id}/delete"
        method: "DELETE"
      )
  }
`;

export const DELETE_BEDROCK_SCHEMA = gql`
  mutation deleteSchema($id: Int!) {
    deleteSchema(id: $id)
      @rest(
        type: "BedrockSchema"
        path: "bedrockplayground/schema/{args.id}/delete"
        method: "DELETE"
      )
  }
`;

export const DELETE_BEDROCK_EXAMPLE = gql`
  mutation deleteExample($id: Int!) {
    deleteExample(id: $id)
      @rest(
        type: "BedrockExample"
        path: "bedrockplayground/example/{args.id}/delete"
        method: "DELETE"
      )
  }
`;

export const DELETE_BEDROCK_RESPONSE = gql`
  mutation deleteResponse($id: Int!) {
    deleteResponse(id: $id)
      @rest(
        type: "BedrockResponse"
        path: "bedrockplayground/response/{args.id}/delete"
        method: "DELETE"
      )
  }
`;

export const UPDATE_IK_NUMBERS = gql`
  mutation updateCompanyIkNumbers($id: Int! $input: Any! $token: String!) {
    updateCompanyIkNumbers(id: $id, input: $input, token: $token)
    @rest(type: "Company", path: "careproviders/{args.id}/iknumbers?token={args.token}", method: "PUT") {
      ${fragments.company()}
    }
  }
`;

export const GET_ONPREMISE_LOGS = gql`
  query onpremiseLogs($careseekerId: Int!, $configId: Int!) {
    getOnpremiseLogs(careseekerId: $careseekerId, configId: $configId)
      @rest(
        type: "OnpremiseLogs"
        path: "onpremise/careseeker/{args.careseekerId}/logs/{args.configId}/viewlogs"
        method: "GET"
      ) {
      message
      timestamp
    }
  }
`;

export const SEND_ONPREMISE_LOGS = gql`
  mutation onpremiseLogs($id: Int!, $input: Any!) {
    setOnpremiseLogs(configId: $configId)
      @rest(
        type: "OnpremiseLogs"
        path: "onpremise/logs/{args.configId}/sendlogs"
        method: "POST"
      ) {
      apistring
    }
  }
`;
