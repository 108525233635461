import {
  ApolloError,
  WatchQueryFetchPolicy,
  useApolloClient,
} from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { Result } from "apollo/utils";
import {
  GET_ACCOUNT,
  GET_AUCTIONREQUEST,
  GET_CAREPROVIDER,
  GET_CAREPROVIDER_TOKEN,
  GET_CARESEEKER,
  GET_CARESEEKER_ACCOUNTS,
  GET_RECEIVER_ACCOUNTS,
} from "core/apollo/graphql";
import { useApolloEncryptionContext } from "core/model/patients/encryption/utils";
import { getErrorMessage } from "core/model/utils/errors";
import { getReferrer } from "core/model/utils/urls";
import {
  Account,
  AuctionRequest,
  Careprovider,
  Careseeker,
  QueryProgress,
} from "core/types";
import { ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApolloLoading, getQueryProgress, toHoc } from "./utils";

const useUpdateUrlForRecommendations = (requestedRequestID: number) => {
  const navigate = useNavigate();
  const client = useApolloClient();

  return ({
    auctionRequest,
  }: {
    auctionRequest: AuctionRequest | undefined;
  }) => {
    if (!auctionRequest?.id || auctionRequest.id === requestedRequestID) return;

    client.writeQuery({
      query: GET_AUCTIONREQUEST,
      variables: {
        id: auctionRequest.id,
        ref: getReferrer(),
      },
      data: {
        auctionRequest,
      },
    });

    navigate(
      {
        pathname: window.location.pathname.replace(
          `/${requestedRequestID}`,
          `/${auctionRequest.id}`,
        ),
        search: location.search,
      },
      { replace: true },
    );
  };
};

const useGetAuctionRequestFetchPolicy = ({
  fetchPolicy,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
}): WatchQueryFetchPolicy => {
  const [search] = useSearchParams();

  const isRecommendation = search.get("recommendation");

  if (isRecommendation) {
    return "cache-first";
  }

  return fetchPolicy ?? "network-only";
};

export const GetAuctionRequestQuery = ({
  auctionRequestId,
  children,
  fetchPolicy,
}: {
  auctionRequestId: number;
  children: (
    auctionRequest: Readonly<AuctionRequest> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const encryptionContext = useApolloEncryptionContext({ decrypt: true });
  const updateUrl = useUpdateUrlForRecommendations(auctionRequestId);
  const policy = useGetAuctionRequestFetchPolicy({
    fetchPolicy,
  });

  return (
    <Query
      query={GET_AUCTIONREQUEST}
      variables={{
        id: auctionRequestId,
        ref: getReferrer(),
      }}
      fetchPolicy={policy}
      skip={auctionRequestId <= 0}
      context={{ encryptionContext }}
      onCompleted={(data: { auctionRequest: AuctionRequest }) => {
        updateUrl({ auctionRequest: data?.auctionRequest });
      }}
    >
      {({
        data,
        error,
        loading,
        refetch,
      }: {
        data: { auctionRequest: AuctionRequest };
        error?: ApolloError;
        loading: boolean;
        refetch: AnyFunction;
      }) => {
        return (
          <ApolloLoading
            loading={loading}
            data={data}
            error={error}
            refetch={refetch}
          >
            {children}
          </ApolloLoading>
        );
      }}
    </Query>
  );
};

export const GetCareproviderQuery = ({
  careproviderId,
  children,
  fetchPolicy,
  token,
  withEvents,
  withInternalNotes,
  withMetrics,
}: {
  careproviderId: number | undefined;
  children: (
    careprovider: Readonly<Careprovider> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  fetchPolicy?: WatchQueryFetchPolicy;
  token?: string;
  withEvents?: boolean;
  withInternalNotes?: boolean;
  withMetrics?: boolean;
}) => (
  <Query<{ careprovider: Careprovider }>
    query={
      token
        ? GET_CAREPROVIDER_TOKEN({ withEvents, withMetrics, token })
        : GET_CAREPROVIDER({ withEvents, withMetrics, withInternalNotes })
    }
    variables={{ id: careproviderId }}
    fetchPolicy={fetchPolicy}
  >
    {({ data, error, loading }) => {
      const careprovider = data?.careprovider;
      return <>{children(careprovider, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetCareseekerQuery = ({
  careseekerId,
  children,
  withAdminFields,
}: {
  careseekerId: number;
  children: (careseeker: Readonly<Careseeker>) => JSX.Element;
  withAdminFields?: boolean;
}) => {
  return (
    <Query<{ careseeker: Careseeker }, { id: number }>
      query={GET_CARESEEKER({ withAdminFields })}
      variables={{ id: careseekerId }}
    >
      {({ data, error, loading }) => {
        return (
          <Result
            data={data?.careseeker}
            getErrorLabel={() => getErrorMessage(error)}
            id="acp-get-careseeker"
            queryProgress={getQueryProgress(loading, error)}
          >
            {children}
          </Result>
        );
      }}
    </Query>
  );
};

export const GetCareseekerAccountsQuery = ({
  careseekerId,
  children,
  fetchPolicy,
}: {
  careseekerId: number;
  children: (
    accounts: Readonly<Account[]> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode;
  fetchPolicy?: WatchQueryFetchPolicy;
}) => (
  <Query<{ accounts: Account[] }>
    query={GET_CARESEEKER_ACCOUNTS}
    variables={{ id: careseekerId }}
    fetchPolicy={fetchPolicy}
  >
    {({ data, error, loading }) => {
      const accounts = data?.accounts;
      return <>{children(accounts, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetReceiverAccountsQuery = ({
  careproviderId,
  children,
  fetchPolicy,
}: {
  careproviderId: number;
  children: (
    accounts: Readonly<Account[]> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  fetchPolicy?: WatchQueryFetchPolicy;
}) => (
  <Query<{ accounts: Account[] }>
    query={GET_RECEIVER_ACCOUNTS}
    variables={{ id: careproviderId }}
    fetchPolicy={fetchPolicy}
  >
    {({ data, error, loading }) => {
      const accounts = data?.accounts;
      return <>{children(accounts, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetAccountQuery = ({
  accountId,
  children,
  realTime,
}: {
  accountId: number | null | undefined;
  children: (
    accounts: Readonly<Account> | null | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  realTime?: boolean;
}) => {
  return (
    <Query<{ account: Account | null }>
      query={GET_ACCOUNT}
      variables={{ id: accountId }}
      fetchPolicy={realTime ? "network-only" : "cache-first"}
      skip={!accountId || accountId < 0}
    >
      {({ data, error, loading }) => {
        const account = data?.account;
        return <>{children(account, getQueryProgress(loading, error))}</>;
      }}
    </Query>
  );
};

export const GetAccountQueryHOC = toHoc(GetAccountQuery, "account");
