import {
  CAREPROVIDER_ACTIVE,
  COMPANY_TYPE_FACILITY,
  SEARCH_TYPE_CARE,
} from "core/consts";
import { Careprovider, DeepNullableObj } from "core/types";
import { defaultsDeep } from "lodash";
import { MockFn, createMock } from "./index";
import mockAccount from "./mockAccount";
import mockEvent from "./mockEvent";

const mockCareprovider: MockFn<Partial<DeepNullableObj<Careprovider>>> = (
  args,
): Careprovider =>
  defaultsDeep(
    args,
    createMock(
      "Careprovider",
      args,
      (shallow): DeepNullableObj<Careprovider> => ({
        __typename: "Careprovider",
        id: 1,
        accounts: [mockAccount({ ...shallow("Account") })],
        activities: {
          invite_contacts_popup: false,
        },
        address: args?.address ?? {
          address: "Soldiner Str. 38",
          city: "Berlin",
          country: "DE",
          latitude: 52,
          longitude: 13,
          street_number: "38",
          street: "Soldiner Str",
          zip_code: "13359",
        },
        assignee: {
          __typename: "Account",
          id: null,
          first_name: null,
          last_name: null,
        },
        capacity: {
          has_capacity: null,
          available_date: null,
          updated_at: null,
        },
        central_email: null,
        central_phone_number: null,
        company: {
          id: null,
          companies: null,
          force_id: null,
          name: null,
          parent_company: null,
          status: null,
          ik_numbers: null,
          company_type: COMPANY_TYPE_FACILITY,
        },
        config: {
          disable_seald: false,
          seald_only_encryption: true,
          seald_config: null,
        },
        contacts: [],
        data_processing_agreement: null,
        duplicated_from: {
          __typename: "Careprovider",
          id: null,
          name: null,
          patient_type: SEARCH_TYPE_CARE,
        },
        email_requests: true,
        email_summary: false,
        events: mockEvent({ ...shallow("Event") }),
        extensions: [
          {
            __typename: "Extension",
            integration_type: 2,
            integration_id: 1,
            active_since: 1642620665,
            connected: true,
          },
        ],
        facilities: null,
        fax_number: null,
        feedback: null,
        files: { images: [], documents: [] },
        inactive_reason: null,
        lead_id: null,
        metrics: {
          accept_count: null,
          attributed_count: null,
          avg_time_to_click: null,
          avg_time_to_open: null,
          avg_time_to_response: null,
          local_accept_count: null,
          local_attributed_count: null,
          local_maybe_count: null,
          local_request_count: null,
          local_response_count: null,
          maybe_count: null,
          request_count: null,
          response_count: null,
        },
        name: "Demo Pflege",
        new: false,
        no_capacities: [],
        onboarding_stats: {
          active_since: null,
          onboarding_source: null,
        },
        onboarding: {
          status: null,
          comment: null,
          call_back_date: null,
          facility_size: null,
          new_facility_openings: null,
          responsible_person: null,
          waiting_list_usage: null,
          information_system: null,
          information_system_other: null,
        },
        opportunity_id: null,
        other_remarks: null,
        patient_type: SEARCH_TYPE_CARE,
        pending_messages: null,
        prerequisites: null,
        profile: {
          accepted_age: { min: null, max: null },
          accepted_weight: { min: null, max: null },
          additional_costs: null,
          cardiovascular_equipment: null,
          caregiver_genders: null,
          confessions: null,
          digestive_endoscopy: null,
          health_insurance_requirement: null,
          imagery: null,
          languages: null,
          opt_out_b2c: false,
          other_technical_equipment: null,
          radius_in_meter: null,
          room_types: null,
          social_worker_on_site: null,
          supported_health_insurance_types: null,
          whitelisted_zipcodes: null,
        },
        receive_fax: false,
        receive_sms: false,
        related_careseekers: [],
        seald_encryption_context: null,
        services: null,
        specializations: null,
        status: CAREPROVIDER_ACTIVE,
        terms_and_conditions: {
          date: null,
          version: null,
        },
        token: null,
        website: null,
        trigger_ppm_self_onboarding: false,
        ppm_receiver_id: null,
        provider_search_info: {
          eligible: null,
          state: null,
        },
      }),
    ),
  );

export default mockCareprovider;
